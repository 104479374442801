:root {
  --svg-logo_width: 45px;
  --svg-swap-icon_width: 45px;
  --fromTextarea_width: 95%;
  --toTextarea_width: 95%;
  --primaryColor: #3c5b90;
  --primaryColor2: #343434;
  --secondaryColor: #eceaea;
  --secondaryColor2: #faddd7;
  --secondaryColor3: #a28465;
  --secondaryColor4: #dbf9d0;
  --focusOutlineColor: white;
  --messageFail_border-color: red;
  --messagePass_border-color: green;
  --message_margin-bottom: -36px;
  --heading-section_margin-top: 0px;
  --page-heading_font-size: 28px;
  --tagline_font-size: 15.5px;
  --selector_font-size: 15px;
  --selector_padding--smaller-on-ff: 7px;
  --textarea_font-size: 14.5px;
  --fetchProgressBar_margin-top: -10px;
  --form-inputs-container_display: block;
  --form-inputs-container_padding: 0 16px 0 9px;
  --button-mobile-go_display: inline-block;
  --button-desktop-go_display: none;
  --footerItem_font-size: 14.5px;
  --messagePass_margin-top: 0;
}

@-moz-document url-prefix() {
  :root {
    --message_margin-bottom: -39px;
    /* To reduce vertical padding when it displays Chinese characters in FF */
    --selector_padding--smaller-on-ff: 4.3px;
  }
}

@media screen and (min-width: 330px) {
  :root {
    --selector_font-size: 16px;
    --form-inputs-container_padding: 0 16px 0 11px;
  }
}

@media screen and (min-width: 375px) {
  :root {
    --selector_font-size: 16.5px;
    --textarea_font-size: 15px;
    --form-inputs-container_padding: 0 16px 0 13px;
  }
}

@media screen and (min-width: 401px) {
  :root {
    --svg-logo_width: 50px;
    --svg-swap-icon_width: 50px;
    --page-heading_font-size: 30px;
    --tagline_font-size: 17.5px;
    --form-inputs-container_padding: 0 16px 0 15px;
    --selector_font-size: 17.5px;
    --textarea_font-size: 15.5px;
    --footerItem_font-size: 15px;
  }
}

@media screen and (min-width: 454px) {
  :root {
    --fromTextarea_width: 368px;
    --toTextarea_width: 368px;
    --form-inputs-container_padding: 0 16px 0 17px;
  }
}

@media screen and (min-width: 475px) {
  :root {
    --form-inputs-container_padding: 0 16px 5px 20px;
  }
}

@media screen and (min-width: 768px) {
  :root {
    --svg-logo_width: 55px;
    --svg-swap-icon_width: 55px;
    --page-heading_font-size: 32px;
    --tagline_font-size: 18.5px;
    --selector_font-size: 18.5px;
    --textarea_font-size: 16px;
    --footerItem_font-size: 15.5px;
  }
}

/* This break point changes the layout from mobile to desktop layout */
/* If you change the width here, then change the following line in App.js: */
/*  --->  const isMobile = windowWidth.current < 864; */
@media screen and (min-width: 864px) {
  :root {
    --focusOutlineColor: rgb(0, 158, 238);
    --fetchProgressBar_margin-top: 15px;
    --button-mobile-go_display: none;
    --button-desktop-go_display: inline-block;
    --form-inputs-container_display: flex;
    --form-inputs-container_padding: 0 16px 5px 18px;
    --footerItem_font-size: 16px;
  }
}

html {
  height: 97%; /* Fallback CSS for IE 4-6 and older browsers. */
  height: 97vh; /* Overrides 100% height in modern HTML5 browsers; uses viewport height. */
}

body {
  background-color: var(--secondaryColor);
  text-align: center;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -webkit-osx-font-smoothing: grayscale;
  height: auto; /* Allows content to grow beyond the page without overflow */
  width: auto; /* Allows content to grow beyond the page without overflow */
  min-height: 97%; /* Fallback for IE 4-6 and older browsers */
  min-height: 97vh; /* For modern HTML5 browsers */
  margin: 0;
  padding: 0;
}

.app {
  margin: 0;
}

#page-heading {
  font-size: var(--page-heading_font-size);
}

#content-container {
  min-height: calc(100vh - 168px);
  margin-top: 30px; /* allocate space for header's messagePass or messageFail */
  margin-bottom: 10px;
  margin-left: auto;
  margin-right: auto;
  max-width: 900px;
}

#footer {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: center;
  background-color: var(--primaryColor2);
  color: white;
  width: 100%;
  position: relative;
  left: 0;
  bottom: 0;
  padding-top: 40px;
  padding-bottom: 60px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

a {
  text-decoration: none;
  color: var(--primaryColor);
}

a:hover {
  color: var(--primaryColor2);
}

a:active {
  color: var(--primaryColor);
}

.footerItem a {
  color: inherit;
  font: inherit;
}

.footerItem a:hover {
  color: inherit;
  font: inherit;
}

.message {
  width: 100%;
  padding: 8px;
  border-left: 0;
  border-right: 0;
  position: fixed;
  margin-top: -30px !important;
}

.messageHidden {
  margin: 0;
}

.messagePass {
  margin: 0 0 var(--message_margin-bottom) -7px;
  border: 1px var(--messagePass_border-color) solid;
  color: var(--primaryColor2);
  background-color: var(--secondaryColor4);
}

.messageFail {
  margin: 0 0 var(--message_margin-bottom) -7px;
  border: 1px var(--messageFail_border-color) solid;
  color: var(--primaryColor2);
  background-color: var(--secondaryColor2);
}

.form-inputs-container {
  display: var(--form-inputs-container_display);
  justify-content: space-around;
  margin: 30px 12px 15px;
  padding: var(--form-inputs-container_padding);
  background-color: var(--primaryColor);
  border: 1px solid var(--primaryColor);
  border-radius: 5px;
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.form-inputs-container--extra-padding-bottom {
  padding-bottom: 10px;
}

.languageBlock {
  padding: 10px 0;
  color: white;
  margin-left: 5px;
  margin-right: 5px;
  flex-grow: 1;
  flex-basis: 0;
}

.heading-section {
  display: flex;
  justify-content: center;
  margin-top: var(--heading-section_margin-top);
  align-items: center;
}

.svg-logo {
  width: var(--svg-logo_width);
  height: auto;
  margin-right: 15px;
}

.tagline {
  color: var(--primaryColor2);
  font-size: var(--tagline_font-size);
  padding: 0 8px;
  margin: 5px;
}

h3 {
  margin: 15px 0;
  font-size: 20px;
}

.selector {
  color: var(--primaryColor);
  background-color: white;
  margin-bottom: 10px;
  padding: 7px;
  width: calc(var(--fromTextarea_width) + 22px);
  border-radius: 5px;
  font-size: var(--selector_font-size);
}

/* Emulate Chrome's dimming effect of disabled selector */
@-moz-document url-prefix() {
  .selector--disabled {
    background-color: #ced7e7;
  }
}

.selector--chinese-characters {
  padding: var(--selector_padding--smaller-on-ff);
}

.selector.empty {
  color: white;
  background: var(--primaryColor);
  border-color: var(--primaryColor);
}

.selector:focus-visible {
  outline-color: var(--focusOutlineColor);
  outline-offset: 0.25rem;
}

.fromTextarea {
  color: black;
  border-radius: 5px;
  width: var(--fromTextarea_width);
  resize: none;
  background-color: white;
  margin-bottom: 10px;
  padding: 10px;
  font-size: var(--textarea_font-size);
}

.fromTextarea:focus-visible {
  outline-color: var(--focusOutlineColor);
  outline-offset: 0.25rem;
}

.toTextarea {
  color: black;
  border-radius: 5px;
  width: var(--fromTextarea_width);
  resize: none;
  background-color: var(--secondaryColor);
  margin-bottom: 10px;
  padding: 10px;
  font-size: var(--textarea_font-size);
}

.toTextarea:focus-visible {
  outline-color: var(--focusOutlineColor);
  outline-offset: 0.25rem;
}

.progressBar {
  margin-top: var(--fetchProgressBar_margin-top);
  margin-bottom: 15px;
  margin-left: 0;
  margin-right: 0;
  height: 2px;
  position: relative;
  overflow: hidden;
  background: var(--primaryColor);
}

.progressBarMobile {
  background: var(--secondaryColor3);
}

.bar {
  position: absolute;
  width: calc(100% * 2 / 7);
  height: 100%;
  display: flex;
  animation: move 0.65s linear infinite;
  background-color: white;
  color: var(--primaryColor);
}

button {
  padding: 20px 25px;
  font-size: 16px;
  border-radius: 5px;
  border-width: 0px;
  background-color: var(--primaryColor);
  color: white;
  font-weight: 600;
  border: 0;
  box-shadow: 5px 5px 25px 1px rgba(0, 0, 0, 0.25);
  cursor: pointer;
  outline: 0;
  transition: 0.1s linear;
}

button:focus-visible {
  outline-offset: 0.25rem;
  outline: 0.25rem solid var(--focusOutlineColor);
  box-shadow: 0 12px 16px 0 rgba(0, 0, 0, 0.24),
    0 17px 50px 0 rgba(0, 0, 0, 0.19);
}

.button-mobile-go {
  display: var(--button-mobile-go_display);
  background-color: var(--secondaryColor);
  color: var(--primaryColor);
}

.button-mobile-swap {
  padding: 12px;
  margin: 0 8px;
}

.button-desktop-go {
  display: var(--button-desktop-go_display);
}

.button-desktop-swap {
  padding: 12px;
  margin: 0 8px;
}

.button-speak {
  width: calc(var(--fromTextarea_width) + 22px);
  padding-top: 5px;
  padding-bottom: 3px;
  background: var(--secondaryColor2);
  margin-bottom: 9px;
  color: var(--primaryColor2);
}

button:hover {
  box-shadow: 0 12px 16px 0 rgba(0, 0, 0, 0.24),
    0 17px 50px 0 rgba(0, 0, 0, 0.19);
}

button:active {
  transform: scale(0.93);
  box-shadow: 3px 2px 25px 1px rgba(0, 0, 0, 0.25);
}

.button-row {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 3px;
}

.footerItem {
  justify-content: left;
  font-size: var(--footerItem_font-size);
  padding: 15px 20px 0;
  flex-grow: 1;
  flex-basis: 0;
  white-space: nowrap;
}

/* remove button styling from "links" */
.tagline > button,
.footerItem > button {
  box-shadow: none;
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
}

.tagline > button {
  text-decoration: none;
  color: var(--primaryColor);
}

.tagline > button:hover {
  color: var(--primaryColor2);
}

.tagline > button:active {
  color: var(--primaryColor);
  transform: none;
}

.footerItem > button:hover {
  color: var(--secondaryColor2);
  box-shadow: none;
}

.footerItem > button:active {
  color: white;
  transform: none;
}

@keyframes move {
  0% {
    transform: translate(-100%, 0);
  }
  100% {
    transform: translate(calc(7 / 2 * 100%), 0);
  }
}

.alerts-border {
  outline: 5px red solid;
  animation: borderBlink 0.8s;
  animation-iteration-count: 3;
}

@keyframes borderBlink {
  from {
    outline-color: red;
  }
  to {
    outline-color: transparent;
  }
  50% {
    outline-color: red;
  }
}
